<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="网关编码">
            <el-input v-model="searchParams.gateway" clearable/>
         </el-form-item>
         <el-form-item label="从机地址">
            <el-input v-model="searchParams.address" clearable/>
         </el-form-item>
       </el-form>
       <el-button style="height:40px;" icon="el-icon-search" type="primary" @click="search">搜索</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="projectCode" label="关联项目" align="left" width="220"/>
      <el-table-column prop="device" label="关联设备" align="left"  width="180"/>
      <el-table-column prop="gateway" label="网关编码" align="left" width="180"/>
      <el-table-column prop="address" label="从机地址" align="left" width="200"/>
      <el-table-column prop="codec" label="解码协议" align="left" width="200"/>
      <el-table-column prop="manufacturerName" label="制造商" align="left" width="150"/>
      <el-table-column prop="categoryName" label="产品类型" align="left" width="150"/>
      <el-table-column prop="modelName" label="产品型号" align="left" width="150"/>
      <el-table-column prop="tenantName" label="关联租户" align="left" width="200"/>
      <el-table-column prop="remark" label="操作" align="center" fixed="right" min-width="100">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="detail(scope.row)" type="text">详情</el-button>
            </div>
          </template>
       </el-table-column>
    </DiluTable>

    <el-dialog title="协议详细" :visible.sync="visible" width="600px" padding-top="10px">
      <JsonView :json="jsonData"></JsonView>
    </el-dialog>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import JsonView from "@/components/view/JsonView.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         gateway: null,
         address: null,       // 从机地址
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      jsonData: {},
      visible: false,
      isSearch: false,
      tableData: [],
      total: 0,
    };
  },
  components: {
    DiluTable,
    JsonView
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    
  },
  methods: {
    detail(e) {
      this.request.protocolDetail({protocol:e.codec}).then((res) => {
          if (res.code==0) {
            this.jsonData = res.data;
            this.visible = true;
          }
      });
    },
    //翻页
    currentChange(e) {
       this.searchParams.pageNum = e;
       this.search();
    },
    //搜索
    search() {
       this.isSearch = true; //是否在查询
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.search();
    },
    //查询列表
    search() {
      this.request.searchCodec(this.searchParams).then((res) => {
          if (res.code==0) {
            this.tableData = res.data;
            this.total = res.dataMaxCount;
          }
      });
    },
  },
};
</script>

<style scoped>
</style>