<template>
   <div class="dilu-page">
    <template v-if="projectCurrentRowData.tenantId=10005">
     <div class="operation-div">
       <el-button :style="{ color: buttonTextColor }" icon="el-icon-setting" type="primary" @click="edit">参数设置</el-button>
       <el-button :style="{ color: buttonTextColor }" icon="el-icon-printer" type="primary" @click="refresh">刷新策略</el-button>
     </div>
     <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
       <el-table-column prop="hours" label="小时" align="center" width="160" :formatter="formatDate"/>
       <el-table-column prop="temperature" label="室外温度" align="center" width="80"/>
       <el-table-column prop="heatForecast" label="热负荷预测值" align="center" width="180"/>
       <el-table-column prop="outForecast" label="供水温度预测值" align="center" width="180"/>
       <el-table-column prop="backForecast" label="回水温度预测值" align="center" width="180"/>
       <el-table-column prop="capfTemp" label="CapFTemp" align="center" width="180"/>
       <el-table-column prop="maxPower" label="最大制热量" align="center" width="180"/>
       <el-table-column prop="startCount" label="开启台数" align="center" width="100"/>
     </DiluTable>
     <SettingEdit :viewVisible="viewVisible" @viewClose="viewClose" :project="searchParams.project"/>
    </template>
    <template v-else>
      [AI调度] 开发中...
    </template>
   </div>
 </template>
 
 <script>
 import DiluTable from "@/components/public/dilu-table.vue";
 import SettingEdit from "./module/settingEdit";
 import { mapState } from "vuex";
 export default {
   data() {
     return {
      searchParams: {
         project: null,      // 项目编码
         pageNum: 1,         // 页码
         pageSize: 24,       // 每页条数
       },
       tableConfig: {
         isTree: false,
         isPage: true,
       },
       tableData: [],
       total: 0,
       viewVisible: false,
       isAdd: true
     };
   },
   components: {
    DiluTable,SettingEdit
   },
   computed: {
    ...mapState(["buttonTextColor","projectCurrentRowData"]),
   },
   mounted() {
    this.searchParams.project=this.projectCurrentRowData.projectCode;
    this.forecastData();
   },
   methods: {
    forecastData() {
      this.request.forecastData(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
      });
    },
    edit(){
      this.viewVisible=true;
    },
    refresh() {
      this.searchParams.pageNum=1;
      this.request.refeshForecast(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
          this.$message.success("刷新成功");
        }
      });
    },
    viewClose() {
      this.viewVisible = false;
    },
    currentChange(e) {
      this.searchParams.pageNum=e;
      this.forecastData();
    },
    formatDate(e) {
      return e.days+" "+e.hours;
    }
   },
 };
 </script>
 
 <style scoped>
 </style>