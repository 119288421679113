<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-upload2" type="primary" @click="goImport">导入</el-button>
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="adjust">校验</el-button>
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-add" type="primary" @click="add" disabled>添加</el-button>
      <el-select v-model="searchParams.paramFlag" placeholder="参数类型" style="width:120px;margin-left:10px;" @change="dictionaryList">
        <el-option label="监控参数" :value=10 />
        <el-option label="应用参数" :value=20 />
        <el-option label="故障参数" :value=30 />
      </el-select>
      <el-select v-model="searchParams.homeFlag" placeholder="首页参数" style="width:120px;margin-left:10px;" @change="dictionaryList" clearable>
        <el-option label="是" :value=10 />
        <el-option label="不是" :value=20 />
      </el-select>
      <el-select v-if="searchParams.paramFlag==20" v-model="searchParams.userEdit" placeholder="谁能设置" style="width:120px;margin-left:10px;" @change="dictionaryList" clearable>
        <el-option label="用户可设置" :value=10 />
        <el-option label="工程可设置" :value=20 />
        <el-option label="都不可设置" :value=30 />
      </el-select>
      <el-input v-model="searchParams.name" placeholder="字典名称" style="width:120px;margin-left:10px;" @keyup.enter.native="dictionaryList"/>
      <el-input v-model="searchParams.groupName" placeholder="分组名称" style="width:120px;margin-left:10px;" @keyup.enter.native="dictionaryList"/>
      <el-input v-model="searchParams.addr" placeholder="地址码" style="width:100px;margin-left:10px;" @keyup.enter.native="dictionaryList"/>
      <el-input v-model="searchParams.readLen" placeholder="字节数" style="width:100px;margin-left:10px;" @keyup.enter.native="dictionaryList"/>
    </div>

    <!-- 表格 -->
      <DiluTable v-if="searchParams.paramFlag==10" :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
        <el-table-column prop="code" label="代码" width="80" align="left"/>
        <el-table-column prop="name" label="参数名称" width="180" align="left"/>
        <el-table-column prop="chinese" label="中文显示" width="180" align="left"/>
        <el-table-column prop="original" label="二次参数" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.original==10">是</span>
            <span v-if="scope.row.original==20">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="fanwei" label="数据范围" width="200" align="left"/>
        <el-table-column prop="saveFlag" label="是否存储" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.saveFlag==10">存储</span>
            <span v-if="scope.row.saveFlag==20">不存储</span>
          </template>
        </el-table-column>
        <el-table-column prop="homeFlag" label="首页参数" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.homeFlag==10">显示</span>
            <span v-if="scope.row.homeFlag==20">不显示</span>
          </template>
        </el-table-column>
        <el-table-column prop="chartFlag" label="数据曲线" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.chartFlag==10">显示</span>
            <span v-if="scope.row.chartFlag==20">不显示</span>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="分组名称" width="120" align="center"/>
        <el-table-column prop="position" label="显示顺序" align="center" width="100"/>
        <el-table-column prop="addr" label="地址码" width="100" align="center"/>
        <el-table-column prop="readCode" label="读功能码" width="100" align="center"/>
        <el-table-column prop="readLen" label="字节数" width="100" align="center"/>
        <el-table-column prop="zoomScale" label="换算比例" width="90" align="center"/>
        <el-table-column prop="digital" label="小数位数" width="90" align="center"/>
        <el-table-column prop="dataType" label="数据类型" width="100" align="center"/>
        <el-table-column prop="unit" label="数据单位" width="90" align="center"/>
        <el-table-column prop="userFlag" label="用户查看" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.userFlag==10">允许</span>
            <span v-if="scope.row.userFlag==20">不允许</span>
          </template>
        </el-table-column>
        <el-table-column prop="readFunc" label="读函数" width="100" align="center"/>
        <el-table-column label="操作" align="center" width="120"  fixed="right">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="edit(scope.row)" type="text" disabled>编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </DiluTable>

      <DiluTable v-if="searchParams.paramFlag==20" :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
        <el-table-column prop="code" label="代码" width="80" align="left"/>
        <el-table-column prop="name" label="参数名称" width="180" align="left"/>
        <el-table-column prop="chinese" label="中文显示" width="180" align="left"/>
        <el-table-column prop="original" label="二次参数" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.original==10">是</span>
            <span v-if="scope.row.original==20">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="fanwei" label="数据范围" width="200" align="left"/>
        <el-table-column prop="saveFlag" label="是否存储" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.saveFlag==10">存储</span>
            <span v-if="scope.row.saveFlag==20">不存储</span>
          </template>
        </el-table-column>
        <el-table-column prop="homeFlag" label="首页参数" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.homeFlag==10">显示</span>
            <span v-if="scope.row.homeFlag==20">不显示</span>
          </template>
        </el-table-column>
        <el-table-column prop="chartFlag" label="数据曲线" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.chartFlag==10">显示</span>
            <span v-if="scope.row.chartFlag==20">不显示</span>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="分组名称" width="120" align="center"/>
        <el-table-column prop="position" label="显示顺序" align="center" width="100"/>
        <el-table-column prop="userEdit" label="谁可设置" width="120" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.userEdit==10">用户可设置</span>
            <span v-if="scope.row.userEdit==20">工程可设置</span>
            <span v-if="scope.row.userEdit==30">都不可设置</span>
          </template>
        </el-table-column>
        <el-table-column prop="addr" label="地址码" width="100" align="center"/>
        <el-table-column prop="readCode" label="读功能码" width="100" align="center"/>
        <el-table-column prop="writeCode" label="写功能码" width="100" align="center"/>
        <el-table-column prop="readLen" label="字节数" width="100" align="center"/>
        <el-table-column prop="dataType" label="数据类型" width="100" align="center"/>
        <el-table-column prop="unit" label="数据单位" width="90" align="center"/>
        <el-table-column prop="writeFunc" label="写函数" width="100" align="center"/>
        <el-table-column prop="readFunc" label="读函数" width="100" align="center"/>
        <el-table-column label="操作" align="center" width="120"  fixed="right">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="edit(scope.row)" type="text" disabled>编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </DiluTable>

      <DiluTable v-if="searchParams.paramFlag==30" :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
        <el-table-column prop="code" label="代码" width="80" align="left"/>
        <el-table-column prop="name" label="参数名称" width="180" align="left"/>
        <el-table-column prop="chinese" label="中文显示" width="180" align="left"/>
        <el-table-column prop="original" label="二次参数" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.original==10">是</span>
            <span v-if="scope.row.original==20">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="fanwei" label="数据范围" width="200" align="left"/>
        <el-table-column prop="homeFlag" label="首页参数" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.homeFlag==10">显示</span>
            <span v-if="scope.row.homeFlag==20">不显示</span>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="分组名称" width="120" align="center"/>
        <el-table-column prop="position" label="显示顺序" align="center" width="100"/>
        <el-table-column prop="addr" label="地址码" width="100" align="center"/>
        <el-table-column prop="readCode" label="读功能码" width="100" align="center"/>
        <el-table-column prop="readLen" label="字节数" width="100" align="center"/>
        <el-table-column  prop="faultLevel" label="故障等级" width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.faultLevel==10">轻微</span>
            <span v-if="scope.row.faultLevel==20">一般</span>
            <span v-if="scope.row.faultLevel==30">严重</span>
          </template>
        </el-table-column>
        <el-table-column  prop="pushSms" label="推送SMS" width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.pushSms==10">推送</span>
            <span v-if="scope.row.pushSms==20">不推送</span>
          </template>
        </el-table-column>
        <el-table-column  prop="pushApp" label="推送APP" width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.pushApp==10">推送</span>
            <span v-if="scope.row.pushApp==20">不推送</span>
          </template>
        </el-table-column>
        <el-table-column prop="readFunc" label="读函数" width="100" align="center"/>
        <el-table-column label="操作" align="center" width="120"  fixed="right">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="edit(scope.row)" type="text" disabled>编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </DiluTable>
      

    <!-- 设置参数 -->
    <SettingDictionary :viewVisible="settingVisible" @viewClose="viewClose" :protocol="protocol" :currentData="currentData" @dictionaryList="dictionaryList" :isAdd="isAdd"/>

    <!-- 监控参数 -->
    <MonitorDictionary :viewVisible="monitorVisible" @viewClose="viewClose" :protocol="protocol" :currentData="currentData" @dictionaryList="dictionaryList" :isAdd="isAdd"/>

    <!-- 故障参数 -->
    <FaultDictionary :viewVisible="faultVisible" @viewClose="viewClose" :protocol="protocol" :currentData="currentData" @dictionaryList="dictionaryList" :isAdd="isAdd"/>

    <!-- 协议Excel导入 --> 
    <ImportDictionary :importVisible="importVisible" @viewClose="viewClose" :protocol="protocol" :protocolName="protocolName" @dictionaryList="dictionaryList"/>

     <!-- 错误详情 -->
     <el-dialog title="错误详情" :visible.sync="errorVisible" width="600px" height="600px" padding-top="10px" style="overflow-y:auto;">
      <json-viewer :value="jsonData" :expand-depth=3 :preview-mode="true" :show-array-index="false" style="height:600px;overflow-y:auto;" show-double-quotes copyable/>
    </el-dialog>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import SettingDictionary from "./module/settingDictionary";
import MonitorDictionary from "./module/monitorDictionary";
import FaultDictionary from "./module/faultDictionary";
import ImportDictionary from "./module/importDictionary";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         protocol: 0,        // 产品协议
         name: "",           // 字典名称
         homeFlag: null,     // 首页参数
         paramFlag: 10,      // 参数类型
         readWrite: null,    // 读写属性
         userEdit: null,     // 谁能设置
         groupName: null,    // 分组名称
         faultLevel: null,   // 严重程度
         addr: null,         // 地址码
         readLen: null,      // 字节数
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      jsonData: [],
      errorVisible: false,
      isSearch: false,
      tableData: [],
      total: 0,
      settingVisible: false,
      monitorVisible: false,
      faultVisible: false,
      importVisible: false,
      currentData: {},
      isAdd: true,
      protocol: 0,
      protocolName: null,
    };
  },
  components: {
    DiluTable,
    SettingDictionary,
    FaultDictionary,
    MonitorDictionary,
    ImportDictionary
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.protocol=this.$route.query.protocol;
    this.protocolName=this.$route.query.protocolName;
    this.dictionaryList();
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    formatDate(row) {
       if (row.createdAt!=undefined&&row.createdAt.length>=10) {
         return row.createdAt.substring(0,10);
       } else {
         return "";
       }
    },
    //翻页
    currentChange(e) {
      this.searchParams.pageNum = e;
      this.dictionaryList();
    },
    //搜索
    search() {
      this.isSearch = true; //是否在查询
      this.searchParams.pageNum = 1;
      this.total = 0;
      this.dictionaryList();
    },
    //重置
    reset() {
      this.isSearch = false; //是否在查询
      this.searchParams.pageNum = 1;
      this.total = 0;
      this.dictionaryList();
    },
    //导入字典
    goImport() {
      this.importVisible=true;
    },
    //规范检查
    adjust() {
      this.request.originalAdjust({"protocol":this.protocol}).then((res) => {
          if (res.code==0) {
            if (res.data!=null&&res.data.hasError=="yes") {
              this.jsonData=res.data;
              this.errorVisible=true;
            } else {
              this.$message.success("很好没有发现错误");
            }
          }
      });
    },
    add() {
      this.isAdd = true;
      this.currentData = null;
      if (this.searchParams.paramFlag==10) {
        //监控参数
        this.monitorVisible=true;
      } else if (this.searchParams.paramFlag==20) {
        //设置参数
        this.settingVisible=true;
      } else if (this.searchParams.paramFlag==30) {
        //故障参数
        this.faultVisible=true;
      }
    },
    //编辑
    edit(e) {
     this.isAdd = false;
     this.currentData = e;
     if (this.searchParams.paramFlag==10) {
      //监控参数
      this.monitorVisible=true;
     } else if (this.searchParams.paramFlag==20) {
      //设置参数
      this.settingVisible=true;
     } else if (this.searchParams.paramFlag==30) {
      //故障参数
      this.faultVisible=true;
     }
    },
    //关闭新增编辑菜单弹窗
   viewClose() {
     this.monitorVisible = false;
     this.settingVisible = false;
     this.faultVisible = false;
     this.importVisible = false;
     this.dictionaryList();
   },
   //查询列表
   dictionaryList() {
    this.searchParams.protocol=this.protocol;
    this.request.dictionaryList(this.searchParams).then((res) => {
        if (res.code==0) {
          this.tableData = res.data;
          this.total = res.dataMaxCount;
        }
    });
   },
  },
};
</script>

<style scoped>
</style>