<template>
  <div class="pro-info info">
    <div class="content-data">
      <div class="summary">
        <div style="width:20%;background:#ffffff;padding-top:10px;padding-left:10px;height:140px;margin-left:5px;">
          <div style="font-weight:bold;margin-bottom:15px;">能耗</div>
          <div style="display:flex;flex-direction:row;height:55px;width:100%;">
            <div style="display:flex;flex-direction:column;width:50%;">
              <div style="margin-top:1px;">{{summary[0].label}}</div>
              <div style="margin-top:1px;font-size:20px;color:#409eff;padding-left:20px;">{{ summary[0].data }}</div>
            </div>
            <div style="display:flex;flex-direction:column;width:50%;">
              <div style="margin-top:1px;">{{summary[1].label}}</div>
              <div style="margin-top:1px;font-size:20px;color:#409eff;padding-left:20px;">{{ summary[1].data }}</div>
            </div>
          </div>
          <div style="display:flex;flex-direction:row;height:55px;">
            <div style="display:flex;flex-direction:column;width:50%;">
              <div style="margin-top:1px;">{{summary[2].label}}</div>
              <div style="margin-top:1px;font-size:20px;color:#409eff;padding-left:20px;">{{ summary[2].data }}</div>
            </div>
            <div style="display:flex;flex-direction:column;width:50%;">
              <div style="margin-top:1px;">{{summary[3].label}}</div>
              <div style="margin-top:1px;font-size:20px;color:#409eff;padding-left:20px;">{{ summary[3].data }}</div>
            </div>
          </div>
        </div>
        <div style="width:20%;background:#ffffff;margin-left:1%;padding-top:10px;padding-left:10px;height:140px;">
          <div style="font-weight:bold;">网络</div>
          <div style="margin-top:15px;">网关数量：{{ staticInfo.gwTotal }}</div>
          <div style="margin-top:10px;">在线数量：{{ staticInfo.gwOnline }}</div>
          <div style="margin-top:10px;">流量状态：Normal</div>
          <div style="margin-top:10px;">最近到期：2025年03月</div>
        </div>
        <div style="width:22%;background:#ffffff;margin-left:1%;height:150px;display:flex;flex-direction:column;align-items:center;justify-content:center;">
          <img :src="projectInfo.projectImage" style="width:96%;margin-left:4%;height:130px;"/>
        </div>
        <div style="width:10%;display:flex;flex-direction:column;background:#ffffff;justify-content:center;align-items:center;height:150px;">
          <canvas ref="qrcodeCanvas" style="width:80px;height:80px;"/>
          <div class="bigscreen" @click="goScreen">大屏</div>
        </div>
        <div style="width:25%;display:flex;flex-direction:column;background:#ffffff;height:150px;justify-content:center;">
          <div style="font-weight:bold;margin-top:5px;">项目编号</div>
          <div style="margin-top:5px;">{{ projectInfo.projectCode }}</div>
          <div style="font-weight:bold;margin-top:15px;">项目名称</div>
          <div style="margin-top:5px;">{{ projectInfo.projectName }}</div>
          <div style="color:#409eff;margin-top:15px;">查看更多</div>
        </div>
      </div>
      <div class="content-body">
        <div class="chart-div">
          <div class="chart-info">{{ chartInfo }}</div>
          <div id="device-chart"/>
        </div>  
        <div style="width:1%">&nbsp;</div>
        <div class="param-div">
          <div class="row-div">
            <div class="param-item">
              <div class="item-name">{{ param.item1.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item1.value }}</div>
            </div>
            <div class="param-item">
              <div class="item-name">{{ param.item2.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item2.value }}</div>
            </div>
          </div>
          <div class="row-div">
            <div class="param-item">
              <div class="item-name">{{ param.item3.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item3.value }}</div>
            </div>
            <div class="param-item">
              <div class="item-name">{{ param.item4.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item4.value }}</div>
            </div>
          </div>
          <div class="row-div">
            <div class="param-item">
              <div class="item-name">{{ param.item5.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item5.value }}</div>
            </div>
            <div class="param-item">
              <div class="item-name">{{ param.item6.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item6.value }}</div>
            </div>
          </div>
          <div class="row-div">
            <div class="param-item">
              <div class="item-name">{{ param.item7.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item7.value }}</div>
            </div>
            <div class="param-item">
              <div class="item-name">{{ param.item8.name }}</div>
              <div style="color:#409eff;margin-top:5px;">{{ param.item8.value }}</div>
            </div>
          </div>
        </div>  
      </div>
      <div class="content-bottom">
        <div style="margin-bottom:10px;font-size:14px;font-weight:bold;">系统图</div>
      </div>
    </div>
    <div class="content-opt">
      <div class="opt-weather">
        <div class="weather-row1">
          <div class="temprature">{{ weatherInfo.temprature }}</div>
          <div class="tianqi">{{ weatherInfo.tianqi }}</div>
          <div class="tqimage"><img :src="imgObj[weatherInfo.image]" style="width:24px;height:24px;"/></div>
        </div>
        <div class="weather-row2">
          <div class="city-area">{{ weatherInfo.city }}，{{ weatherInfo.area }}</div>
          <div style="display:flex;width:25%;justify-content:right;"><div class="air">{{ weatherInfo.air }}</div></div>
        </div>
        <div class="weather-row3">
          <div class="humidity">湿度：{{ weatherInfo.humidity }}</div>
          <div class="wind">风力：{{ weatherInfo.wind }}</div>
        </div>
      </div>
      <div class="system">
        <div style="margin-bottom:10px;font-size:14px;font-weight:bold;">系统信息</div>
        <div class="system-row">
          <div style="width:20%;">
            <img :src="imgObj.machine" style="width:24px;height:24px;"/>
          </div>
          <div style="width:70%;">机器设备</div>
          <div style="width:10%;">{{staticInfo.devTotal}}</div>
        </div>
        <div class="system-row">
          <div style="width:20%;">
            <img :src="imgObj.aftersales" style="width:24px;height:24px;"/>
          </div>
          <div style="width:70%;">售后服务工单</div>
          <div style="width:10%;">3</div>
        </div>
        <div class="system-row">
          <div style="width:20%;">
            <img :src="imgObj.eventmsg" style="width:24px;height:24px;"/>
          </div>
          <div style="width:70%;">本月故障消息</div>
          <div style="width:10%;">5</div>
        </div>
        <div class="system-row">
          <div style="width:20%;">
            <img :src="imgObj.iuser" style="width:24px;height:24px;"/>
          </div>
          <div style="width:70%;">内部用户</div>
          <div style="width:10%;">2</div>
        </div>
        <div class="system-row">
          <div style="width:20%;">
            <img :src="imgObj.euser" style="width:24px;height:24px;"/>
          </div>
          <div style="width:70%;">终端用户</div>
          <div style="width:10%;">1</div>
        </div>
      </div>
      <div class="devices">
        <div style="margin-bottom:10px;font-size:14px;font-weight:bold;">设备列表</div>
        <div class="device-item" v-for="(device, index) in deviceList" :key="index">
          <div class="online" v-if="device.online=='yes'">
            {{ device.productNick }} - {{ subStr(device.gateway) }}
          </div>
          <div v-else class="offline">
            {{ device.productNick }} - {{ subStr(device.gateway) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import * as echarts from "echarts";
import QRCode from 'qrcode';
import { DateToHHmm, formatNumberWithCommas } from "../../../utils/util/util";
export default {
  name: "ProInfo",
  data() {
    return {
      summary: [
        {
          id: "jianzhu",
          label: "建筑面积",
          data: "0",
          icon: require("./images/gongnuan.png")
        },
        {
          id: "lengnuan",
          label: "冷暖面积",
          data: "0",
          icon: require("./images/danweinenghao.png")
        },
        {
          id: "zongnenghao",
          label: "总计能耗",
          data: "0",
          icon: require("./images/zongnenghao.png")
        },
        {
          id: "jiantan",
          label: "减碳总计",
          data: "0",
          icon: require("./images/jiantan.png")
        },
      ],
      param: {
        item1: {
          name:"参数名称(未配置)",
          value:"-单位"
        },
        item2: {
          name:"参数名称(未配置)",
          value:"-单位"
        },
        item3: {
          name:"参数名称(未配置)",
          value:"-单位"
        },
        item4: {
          name:"参数名称(未配置)",
          value:"-单位"
        },
        item5: {
          name:"参数名称(未配置)",
          value:"-单位"
        },
        item6: {
          name:"参数名称(未配置)",
          value:"-单位"
        },
        item7: {
          name:"参数名称(未配置)",
          value:"-单位"
        },
        item8: {
          name:"参数名称(未配置)",
          value:"-单位"
        }
      },
      placeProps: {
          children: "nodeList",
          label: "name"
      },
      imgObj: {
        aftersales: require("./images/icon_js_aftersales.png"),
        euser: require("./images/icon_js_euser.png"),
        eventmsg: require("./images/icon_js_eventmsg.png"),
        iuser: require("./images/icon_js_iuser.png"),
        machine: require("./images/icon_js_machine.png"),
        qing: require("@/assets/images/weather/qing.png"),
        dalei: require("@/assets/images/weather/dalei.png"),
        dawu: require("@/assets/images/weather/dawu.png"),
        duoyun: require("@/assets/images/weather/duoyun.png"),
        bingbao: require("@/assets/images/weather/bingbao.png"),
        shachen: require("@/assets/images/weather/shachen.png"),
        xue: require("@/assets/images/weather/xue.png"),
        yin: require("@/assets/images/weather/yin.png"),
        yu: require("@/assets/images/weather/yu.png"),
        feng: require("@/assets/images/weather/feng.png"),
        yun: require("@/assets/images/weather/yun.png"),
        person: require("./images/person.png"),
        repair: require("./images/repair.png"),
        scene: require("./images/scene.png"),
        tuzhi: require("./images/tuzhi.png"),
      },
      iconGateway: require("./images/wangguan.png"),
      iconDevice: require("./images/shebei.png"),
      weatherInfo: {
        city:"",
        area:"",
        tianqi:"",
        temprature:"",
        air:"",
        wind:"",
        co2:"",
        pm25:"",
        humidity:"",
        tvoc:"",
        image:"qing"
      },
      projectInfo: {},
      projectChart: {},
      deviceList: [],
      chartInfo: "总览曲线(未配置)",
      staticInfo: {
        gwTotal: 0,
        gwOnline: 0,
        gwOffline: 0,
        gwPercent: "0%",
        devTotal: 0,
        devOnline: 0,
        devOffline: 0,
        devPercent: "0%",
      }
    };
  },
  computed: {
    ...mapState(["projectCurrentRowData"]),
  },
  mounted() {
    this.projectInfo=this.projectCurrentRowData;
    this.projectChartRead();
    this.initShow();
    this.projectStatic();
    this.screenWeather();
    this.getDeviceList();
    this.generateQRCode();
  },
  methods: {
    generateQRCode() {
      const canvas = this.$refs.qrcodeCanvas;
      QRCode.toCanvas(canvas, this.projectInfo.projectCode, {width:42,height:42}, error => {if (error) console.error(error)});
    },
    getDeviceList() {
      var params={
        "project":this.projectInfo.projectCode
      };
      this.request.deviceList(params).then((res) => {
        if (res.code == 0) {
          this.deviceList=res.data;
        }
      });
    },
    goScreen() {
      if (this.projectCurrentRowData.screen) {
        this.$router.push({
          path: this.projectCurrentRowData.screen,
          query: {
            projectCode: this.projectCurrentRowData.projectCode,
          },
        });
      } else {
        this.$message.error("该项目没有大屏");
      }
    },
    goScene() {
      this.$router.push({
          path: "/project/proinfo/scene",
      });
    },
    goTuzhi() {

    },
    goPerson() {
      this.$router.push({
          path: "/project/proinfo/user",
      });
    },
    goRepair() {
      this.$router.push({
          path: "/project/proinfo/serve",
      });
    },
    screenWeather() {
      var params={
        "project":this.projectInfo.projectCode,
        "airFlag":"yes"
      };
      this.request.screenWeather(params).then((res) => {
        if (res.code == 0) {
          this.weatherInfo=res.data;
        }
      });
    },
    projectStatic() {
      var params={
        "project":this.projectInfo.projectCode,
        "gateway":"yes",
        "device":"yes"
      };
      this.request.projectStatic(params).then((res) => {
        if (res.code == 0) {
          this.staticInfo=res.data;
        }
      });
    },
    projectChartRead() {
      if (this.projectInfo.realChart!=null) { 
        var params={"project":this.projectInfo.projectCode};
        this.request.projectChartRead(params).then((res) => {
          if (res.code == 0) {
            this.projectChart=res.data;
            this.chartInfo=this.projectChart.deviceName+" - "+this.projectChart.paramName+" "+this.projectChart.unit;
            this.showChart();
            this.showParams();
          } else {
            this.chartInfo="设备参数读取异常（"+res.msg+"）";
          }
        });
      }
    },
    showParams() {
      if (this.projectChart.homeParams!=null&&this.projectChart.homeParams.length>0) {
        for (var i=0; i<this.projectChart.homeParams.length; i++) {
          var item=this.projectChart.homeParams[i];
          if (i==0) {
              this.param.item1.name=item.chinese;
              this.param.item1.value=this.getValue(item);
          }
          if (i==1) {
              this.param.item2.name=item.chinese;
              this.param.item2.value=this.getValue(item);
          }
          if (i==2) {
              this.param.item3.name=item.chinese;
              this.param.item3.value=this.getValue(item);
          }
          if (i==3) {
              this.param.item4.name=item.chinese;
              this.param.item4.value=this.getValue(item);
          }
          if (i==4) {
              this.param.item5.name=item.chinese;
              this.param.item5.value=this.getValue(item);
          }
          if (i==5) {
              this.param.item6.name=item.chinese;
              this.param.item6.value=this.getValue(item);
          }
          if (i==6) {
              this.param.item7.name=item.chinese;
              this.param.item7.value=this.getValue(item);
          }
          if (i==7) {
              this.param.item8.name=item.chinese;
              this.param.item8.value=this.getValue(item);
          }
        }
      }
    },
    getValue(item) {
      if (item.value==null||item.value=="") {
        return "--";
      } else {
        if (item.fanwei==null||item.fanwei.includes('~')||item.fanwei.includes(';')==false) {
          if (item.unit!=null) {
            return item.value+item.unit;
          } else {
            return item.value;
          }
				} else {
          var list=[];
          item.fanwei.split(";").forEach((optStr) => {
            const [id, name] = optStr.split(':');
            const opt = { id, name };
            list.push(opt);
          });
					for (var i=0; i<list.length; i++) {
						if (item.value==list[i].id) {
							return list[i].name;
						}
					}
          if (item.unit!=null) {
            return item.value+item.unit;
          } else {
            return item.value;
          }
				}
      }
    },
    showChart() {
      if (this.projectChart.deviceId!=null&&this.projectChart.paramName!=null) {
        var params={
          "deviceId":this.projectChart.deviceId,
          "items":this.projectChart.paramName
        };
        this.request.readDateParams(params).then((res) => {
          if (res.code==0) {
            if (res.data!=undefined&&res.data!=null&&res.data.length>0) {
              let date = [];
              let data = [];
              let list=res.data[0].data;
              if (list!=null&&list.length>0) {
                for (let i=0; i<list.length; i++) {
                  var time=DateToHHmm(new Date(parseInt(list[i].time)*1000));
                  date.push(time);
                  data.push(list[i].value);
                }
              }
              var chartDom = document.getElementById("device-chart");
              var myChart = echarts.init(chartDom,'light');
              var option = {
                xAxis: {
                  type: "category",
                  boundaryGap: false,
                  data: date,
                },
                yAxis: {
                  type: "value",
                  splitLine: {
                    show: false,
                  },
                  axisLine: {
                    show: true,
                  },
                },
                tooltip: {
                  trigger: "axis",
                },
                grid: {
                  left: 40,
                  right: 15,
                  top: 15,
                  bottom: 35,
                },
                series: [
                  {
                    data: data,
                    areaStyle: {},
                    type: "line",
                    label: {
                      show: false,
                    },
                  },
                ],
              };
              option && myChart.setOption(option);
            }
          } else {
            this.chartInfo="设备曲线读取异常（"+res.msg+"）";
          }
        });
      } else {
        this.chartInfo="未设置设备参数曲线";
      }
    },
    initShow() {
      this.summary[0].data=formatNumberWithCommas(this.projectInfo.cainuanArea);
      this.summary[3].data=formatNumberWithCommas(this.projectInfo.kongtiaoArea);
    },
    projectAddress() {
      if (this.projectInfo.address!=null&&this.projectInfo.address.length>10) {
        return this.projectInfo.address;
      } else if (this.projectInfo.province!=null&&this.projectInfo.province!=undefined) {
        return this.projectInfo.province.split(",")[1]+" "+this.projectInfo.city.split(",")[1]+" "+this.projectInfo.area.split(",")[1]+" "+this.projectInfo.town+this.projectInfo.address;
      } else {
        return "无";
      }
    },
    formatStatus(status) {
      if (status==10) {
        return "在建";
      } else if (status==20) {
        return "试运行";
      } else if (status==30) {
        return "已交付";
      } else {
        return "未知";
      }
    },
    subStr(gateway) {
      if (gateway==null||gateway=='') {
        return "";
      } else {
        if (gateway.length<=4) {
          return gateway;
        } else {
          return " ("+gateway.substr(gateway.length-4,4)+")";
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
.pro-info {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: whitesmoke;
  .content-data {
    width: 79%;
    margin: 5px;
    .summary {
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content-opt {
    width: 20%;
    margin: 5px;
    .opt-weather {
      height: 150px;
      opacity: 1;
      background: linear-gradient(304deg, #ffffff 22%, #f5faff 67%);
    }
  }
}
.weather-row3 {
  display: flex;
  width: 97%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  padding-top: 20px;
}
.humidity {
  margin-left: 10px;
  width: 45%;
}
.wind {
  width: 50%;
}
.weather-row2 {
  display: flex;
  width: 97%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  padding-top: 20px;
}
.city-area {
  padding-left: 10px;
  width: 70%;
  text-align: left;
}
.air {
  width: 48px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: linear-gradient(to right, green, #24E883);
  border-radius: 5px;
}
.tqimage {
  width: 48%;
  text-align: right;
}
.tianqi {
  font-size: 17px;
  width: 25%;
  text-align: left;
}
.temprature {
  font-size: 20px;
  font-weight: bold;
  width: 25%;
  text-align: center;
}
.weather-row1 {
  display: flex;
  width: 97%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  padding-top: 15px;
}
.tree {
    height: 100%;
    width: 100%;
    overflow: auto;
}
.chart-div {
  width: 55%;
  height: 250px;
  background: #ffffff;
  padding-top: 10px;
  padding-bottom: 5px;
}
.param-div {
  width: 44%;
  height: 250px;
  background: #ffffff;
  padding-top: 10px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
}
.row-div{
  display: flex;
  flex-direction: row;
  width:100%;
  height: 70px;
  margin-top: 10px;
}
.param-item {
  display: flex;
  flex-direction: column;
  width: 42%;
  margin-left: 8%;
}
.chart-info {
  margin-left:10px;
  margin-bottom: 6px;
  margin-top: 4px;
  width: 225px;
  font-size:14px;
}
.bigscreen {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: #409eff;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  text-align: center;
}
#device-chart {
  width: 100%;
  height: 225px;
}
.gateway-info {
  width: 100%;
  height: 200px;
  background: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;
}
.gateway-title {
  display: flex;
  align-items: center;
  padding-left:20px;
  padding-top:30px;
  width: 200px;
  font-size: 16px;
}
.device-info {
  width: 100%;
  height: 200px;
  background: #ffffff;
  margin-bottom: 10px;
}
.online {
  color: green
}
.offline {
  color: gray
}
.device-item{
  margin-top: 5px;
}
.device-title {
  display: flex;
  align-items: center;
  padding-left:20px;
  padding-top:30px;
  width: 200px;
  font-size: 16px;
}
.bold-data{
  font-weight: bold;
  margin-right: 40px;
}
.system-row {
  width: 94%;
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-body {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-left: 5px;
}
.content-bottom {
  height: 285px;
  margin-top: 10px;
  padding: 10px;
  margin-left: 5px;
  background: white;
}
.system {
  height: 250px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  background: white;
  display: flex;
  flex-direction: column;
}
.devices {
  height: 285px;
  margin-top: 10px;
  padding: 10px;
  background: white;
  overflow-y: auto;
}
.btndiv {
  width: 100%;
  height: 140px;
  padding-top: 20px;
  background-color: white;
  margin-top: 10px;
  text-align: center;
}
.btnimg {
  margin-bottom: 5px;
  width:96px;
  height:96px;
}
.imgdiv {
  width:100%;
  text-align:center;
}
.txtdiv {
  width: 100%;
  margin-top: -15px;
}
.row-item{
  width:98%;
  margin-left:1%;
  margin-right:1%;
  margin-top:15px;
  display:flex;
  align-items:center;
}
.item-name {
  font-size:14px;
  height:20px;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
