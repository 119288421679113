import { render, staticRenderFns } from "./gatewayAdd.vue?vue&type=template&id=b3cd884c&scoped=true"
import script from "./gatewayAdd.vue?vue&type=script&lang=js"
export * from "./gatewayAdd.vue?vue&type=script&lang=js"
import style0 from "./gatewayAdd.vue?vue&type=style&index=0&id=b3cd884c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3cd884c",
  null
  
)

export default component.exports