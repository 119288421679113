<template>
  <div>
    <el-dialog
      title="导入协议字典"
      :visible.sync="importVisible"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      center>
      <div class="dialog-content">
        <el-form ref="form" :model="submitForm" label-width="auto">
          <el-form-item label="下载模板">
            <div class="form-item-div">
              <el-button type="primary" @click="exportTemplate" size="small">下载模板</el-button>
            </div>
          </el-form-item>
          <el-form-item label="文件上传">
            <div class="form-item-div">
              <DiluUploadFile
                :limit="1"
                accept=".xls,.xlsx"
                :fileList="fileList"
                @remove="remove"
                @success="success"
                :uploaDisabled="false"
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="doImport">确定</el-button>
      </span>
    </el-dialog>

    <!-- 错误详情 -->
    <el-dialog title="错误详情" :visible.sync="detailVisible" width="600px" height="600px" padding-top="10px" style="overflow-y:auto;">
      <json-viewer :value="jsonData" :expand-depth=3 :preview-mode="true" :show-array-index="false" style="height:600px;overflow-y:auto;" show-double-quotes copyable/>
    </el-dialog>

  </div>
</template>

<script>
import DiluUploadFile from "@/components/public/dilu-uploadfile.vue";
import { DateToShort } from "@/utils/util/util";
export default {
  data() {
    return {
      submitForm: {
        protocol: null, //协议ID
        excel: null,      //协议Excel
        name: null,     //文件名称
      },
      loading: false,
      fileList: [],
      jsonData: [],
      detailVisible: false,
    };
  },
  components: {
    DiluUploadFile,
  },
  computed: {},
  props: ["protocol","importVisible","protocolName"],
  methods: {
    handleClose() {
      this.submitForm = {
        protocol: null, //协议ID
        excel: null,      //协议Excel
        name: null,     //文件名称
      };
      this.fileList = [];
      this.$emit("viewClose");
    },
    //下载模版
    exportTemplate() {
      this.request.exportTemplate(this.protocolName+'['+DateToShort(new Date())+']',"xlsx").then();
    },
    success(e) {
      this.fileList = e.fileList;
      this.submitForm.excel = e.url;
      this.submitForm.name = this.fileList[0].name;
    },
    remove(e) {
      const i = this.fileList.findIndex((x) => x.name == e);
      this.fileList.splice(i, 1);
      this.submitForm.excel = null;
      this.submitForm.name = null;
    },
    //确定
    doImport() {
      this.submitForm.protocol=this.protocol;
      if (this.submitForm.excel==''||this.submitForm.excel==null) {
        return this.$message.error("请选择Excel后上传");
      }
      if (this.submitForm.protocol==null||this.submitForm.protocol=='') {
        return this.$message.error("请选择设备协议");
      }
      if (this.submitForm.name==null||this.submitForm.name=='') {
        return this.$message.error("请设置Excel文件名");
      }
      this.loading = true;
      this.request.importDictionary(this.submitForm).then((res) => {
          if (res.code==0) {
            this.$message.success("导入成功");
            this.loading = false;
            this.handleClose();
          } else {
            this.loading = false;
            this.handleClose();
            if (res.data!=null) {
              this.jsonData=res.data;
            } else {
              this.jsonData={
                "错误代码": res.code,
                "错误信息": res.msg
              }
            }
            this.detailVisible=true;
          }
        })
        .catch(() => {
          this.$message.error("导入失败");
          this.loading = false;
        });
    },
  },
};
</script>


<style scoped>
.dialog-content {
  padding: 0 80px;
  box-sizing: border-box;
}
.form-item-div {
  display: flex;
  align-items: center;
}
.form-item-div span {
  display: block;
  text-align: center;
  flex-shrink: 0;
  margin-left: 10px;
}
</style>