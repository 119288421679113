<template>
   <div class="dilu-page">
     <div class="search">
       <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
         <el-form-item prop="gateway" label="网关编号">
           <el-input v-model="searchParams.gateway" style="width:180px;margin-right:15px;" clearable/>
         </el-form-item>
         <el-form-item label="升级状态">
            <el-select v-model="searchParams.result" placeholder="请选择" style="width:180px;" clearable>
              <el-option label="未开始" value=10 />
              <el-option label="已发布" value=20 />
              <el-option label="已处理" value=30 />
              <el-option label="升级成功" value=40 />
              <el-option label="升级失败" value=50 />
              <el-option label="已取消" value=60 />
            </el-select>
         </el-form-item>
       </el-form>
       <div class="search-submit">
        <el-button v-if="grayTask.status==10" icon="el-icon-plus" type="primary" @click="addDtu">添加设备</el-button>
        <el-button icon="el-icon-search" type="success" @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh" type="reset" @click="reset">重置</el-button>
       </div>
     </div>
     <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
       <el-table-column prop="gateway" label="网关编号" width="200" align="left"/>
       <el-table-column prop="stype" label="网关类型" width="150" align="left">
          <template slot-scope="scope">
              <span v-if="scope.row.stype == 10">模块</span>
              <span v-if="scope.row.stype == 20">边缘网关</span>
              <span v-if="scope.row.stype == 30">边缘服务器</span>
          </template>
       </el-table-column>
       <el-table-column prop="result" label="升级结果" width="150" align="center">
          <template slot-scope="scope">
              <span v-if="scope.row.result == 10">未开始</span>
              <span v-if="scope.row.result == 20">已发布</span>
              <span v-if="scope.row.result == 30">升级中</span>
              <span v-if="scope.row.result == 40">升级成功</span>
              <span v-if="scope.row.result == 50">升级失败</span>
              <span v-if="scope.row.result == 60">已取消</span>
          </template>
       </el-table-column>
       <el-table-column prop="targetVersion" label="目标版本" width="180" align="left"/>
       <el-table-column prop="currentVersion" label="当前版本" width="180" align="left"/>
       <el-table-column prop="sendDate" label="任务发布" width="180" align="center"/>
       <el-table-column prop="handleDate" label="任务应答" width="180" align="center"/>
       <el-table-column prop="replyDate" label="升级应答" width="180" align="center"/>
       <el-table-column prop="projectCode" label="关联项目" width="200" align="left"/>
       <el-table-column prop="projectName" label="项目名称" width="200" align="left"/>
       <el-table-column prop="remark" label="备注信息" width="180" align="left"/>
       <el-table-column prop="remark" label="操作" align="center" fixed="right" min-width="120">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button v-if="scope.row.result == 10" @click="remove(scope.row)" type="text">删除</el-button>
              <div v-else>无</div>
            </div>
          </template>
       </el-table-column>
      </DiluTable>

     <!--新增OTA网关-->
     <AddOtaDtu :grayTask="grayTask" :editVisible="editVisible" @handleClose="handleClose"/>

   </div>
 </template>
 
 <script>
 import AddOtaDtu from "./module/addOtaDtu.vue";
 import DiluTable from "@/components/public/dilu-table.vue";
 export default {
   data() {
     return {
       tableConfig: {
         isTree: false,
         isPage: true, 
       },
       searchParams: {
         taskId: null,
         gateway: null,
         result: null,
         pageNum: 1, 
         pageSize: 12,
       },
       editVisible: false, 
       grayTask: {},
       total: 0,
       tableData: [],
     };
   },
   components: {
     DiluTable,
     AddOtaDtu
   },
   mounted() {
     this.searchParams.taskId = this.$route.query.taskId;
     this.getGrayTask();
     this.getDtuProgress();
   },
   methods: {
     getGrayTask() {
       var params={taskId:this.searchParams.taskId};
       this.request.taskDtuInfo(params).then((res) => {
        if (res.code == 0) {
          this.grayTask=res.data;
        }
      });
     },
     getDtuProgress() {
      this.request.taskDtuDetail(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData=res.data;
          this.total=res.dataMaxCount;
        }
      });
     },
     search() {
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.getDtuProgress();
     },
     currentChange(e) {
       this.searchParams.pageNum = e;
       this.getDtuProgress();
     },
     dialogClose() {
       this.editVisible = false;
     },
     addDtu() {
       this.editStatus=0;
       this.editVisible = true;
     },
     remove(e) {
      this.$confirm("确认要删除么?", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var params={
          taskId: this.searchParams.taskId,
          deviceId: e.id
        };
        this.request.removeOta(params).then((res) => {
          if (res.code == 0) {
            this.$message.success("操作成功");
            this.getDtuProgress();
          }
        });
      });
     },
     reset() {
      this.total = 0;
      this.searchParams.gateway = null;
      this.searchParams.result = null;
      this.getDtuProgress();
     },
     handleClose() {
      this.editVisible=false;
      this.getDtuProgress();
     },
   },
 };
 </script>
 
 <style scoped>
 </style>