<template>
   <div class="dilu-page">
    <div class="search">
       <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
         <el-form-item label="固件名称">
           <el-input v-model="searchParams.firmwareName" style="width:150px;margin-right:15px;" clearable/>
         </el-form-item>
         <el-form-item label="固件版本">
           <el-input v-model="searchParams.firmwareVersion" style="width:150px;margin-right:15px;" clearable/>
         </el-form-item>
         <el-form-item label="固件类别">
            <el-select v-model="searchParams.firmwareType" placeholder="请选择" style="width:150px;margin-right:15px;" clearable>
              <el-option label="模块" value=10 />
              <el-option label="边缘网关" value=20 />
              <el-option label="边缘服务器" value=30 />
            </el-select>
         </el-form-item>
         <el-form-item label="发布状态">
            <el-select v-model="searchParams.status" placeholder="请选择" style="width:150px;margin-right:15px;" clearable>
              <el-option label="未发布" value=10 />
              <el-option label="已发布" value=20 />
              <el-option label="已删除" value=30 />
            </el-select>
         </el-form-item>
       </el-form>
       <div class="search-submit">
        <el-button icon="el-icon-plus" type="primary" @click="add" style="margin-right:30px;">新建灰度</el-button>
        <el-button icon="el-icon-search" type="success" @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh" type="reset" @click="reset">重置</el-button>
       </div>
     </div>
     <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
       <el-table-column prop="firmwareName" label="固件名称" width="180" align="left"/>
       <el-table-column label="完成进展" width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.total > 0">{{(scope.row.success*100)/scope.row.total}}%</span>
            <span v-else>NA</span>
          </template>
       </el-table-column>
       <el-table-column prop="total" label="总设备数" width="100" align="center"/>
       <el-table-column prop="success" label="升级成功" width="100" align="center"/>
       <el-table-column prop="failed" label="升级失败" width="100" align="center"/>
       <el-table-column prop="status" label="发布状态" width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 10">未发布</span>
            <span v-if="scope.row.status == 20 && scope.row.remark!='released'">已发布</span>
            <span v-if="scope.row.status == 30">已删除</span>
            <span v-if="scope.row.status == 20 && scope.row.remark=='released'">已完成</span>
          </template>
       </el-table-column>
       <el-table-column prop="firmwareVersion" label="固件版本" width="120" align="left"/>
       <el-table-column prop="firmwareType" label="固件类别" width="130" align="left">
          <template slot-scope="scope">
            <span v-if="scope.row.firmwareType == 10">模块</span>
            <span v-if="scope.row.firmwareType == 20">边缘网关</span>
            <span v-if="scope.row.firmwareType == 30">边缘服务器</span>
          </template>
       </el-table-column>
       <el-table-column prop="matched" label="匹配版本" width="120" align="center"/>
       <el-table-column prop="compare" label="匹配条件" width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.compare == 'eq'">等于</span>
            <span v-if="scope.row.compare == 'ne'">不等于</span>
          </template>
       </el-table-column>
       <el-table-column prop="startDate" label="开始日期" width="120" align="center"/>
       <el-table-column prop="startTime" label="开始时间" width="100" align="center"/>
       <el-table-column prop="createdAt" label="创建时间" width="200" align="center"/>
       <el-table-column prop="createdBy" label="创建人" width="100" align="center"/>
       <el-table-column prop="remark" label="操作" align="center" fixed="right" min-width="250">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button v-if="scope.row.status==10" @click="edit(scope.row)" type="text">编辑</el-button>
              <el-button @click="del(scope.row)" type="text">删除</el-button>
              <el-button @click="device(scope.row)" v-if="scope.row.status==10" type="text">添加设备</el-button>
              <el-button @click="device(scope.row)" v-else type="text">升级进展</el-button>
              <el-button v-if="scope.row.status==10" @click="publish(scope.row)" type="text">提交发布</el-button>
              <el-button v-if="scope.row.status==20 && scope.row.remark!='released'" @click="release(scope.row)" type="text">转正式升级</el-button>
            </div>
          </template>
       </el-table-column>
     </DiluTable>

     <!--新增灰度测试-->
     <AddGrayTaskDtu :taskItem="taskItem" :editStatus="editStatus" :editVisible="editVisible" @handleClose="handleClose"/>

   </div>
 </template>
 
 <script>
 import AddGrayTaskDtu from "./module/addGrayDtu.vue";
 import DiluTable from "@/components/public/dilu-table.vue";
 export default {
   data() {
     return {
       searchParams: {
         taskType: 10,
         firmwareName: "",
         firmwareVersion: "",
         firmwareType: "", 
         status: null,
         pageNum: 1, 
         pageSize: 12,
       },
       tableConfig: {
         isTree: false,
         isPage: true, 
       },
       editVisible: false, 
       editStatus: 0,
       taskItem: {},
       total: 0,
       tableData: [],
     };
   },
   components: {
     DiluTable,
     AddGrayTaskDtu
   },
   mounted() {
     this.upgradeTaskList();
   },
   methods: {
     upgradeTaskList() {
      this.request.dtuTaskList(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData=res.data;
          this.total=res.dataMaxCount;
        }
      });
     },
     search() {
       this.searchParams.pageNum = 1;
       this.total = 0;
       this.upgradeTaskList();
     },
     add() {
       this.editStatus=0;
       this.editVisible = true;
       this.taskItem = {};
     },
     edit(e) {
       this.editStatus=1;
       this.taskItem = e;
       this.editVisible = true;
     },
     del(e) {
      this.$confirm("确认要删除么?", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var params={taskId:e.id};
        this.request.taskDelete(params).then((res) => {
          if (res.code == 0) {
            e.status=30;
            this.upgradeTaskList();
            this.$message.success("操作成功");
          }
        });
      });
     },
     publish(e) {
      var params={taskId:e.id};
      this.request.taskPublish(params).then((res) => {
        if (res.code == 0) {
          e.status=20;
          this.$message.success("操作成功");
        }
      });
     },
     release(e) {
      var params={taskId:e.id};
      this.request.taskRelease(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("操作成功");
          this.$router.push({
            path: "/serve/otadtu/releaseDtu",
            query: {
              taskId: e.id,
            },
          });
        }
      });
     },
     device(e) {
      this.$router.push({
        path: "/serve/otadtu/progressDtu",
        query: {
          taskId: e.id,
        },
      });
     },
     //翻页
     currentChange(e) {
       this.searchParams.pageNum = e;
       this.upgradeTaskList();
     },
     //关闭新增编辑弹窗
     dialogClose() {
       this.editVisible = false;
     },
     reset() {
      this.total = 0;
      this.searchParams.firmwareName = "";
      this.searchParams.firmwareVersion = "";
      this.searchParams.firmwareType = "";
      this.searchParams.status = "";
      this.upgradeTaskList();
    },
    handleClose() {
      this.editVisible=false;
      this.upgradeTaskList();
    },
   },
 };
 </script>
 
 <style scoped>
 </style>