<template>
  <el-dialog :title="id == null ? '新增设备' : '编辑设备'" :visible.sync="orgVisible" width="650px"
    :modal-append-to-body='false' :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="类型" prop="stype" style="width: 100%;">
          <el-radio-group v-model="ruleForm.stype">
            <el-radio v-for="(item, index) in radiolist1" :label="item.val" :key="index">{{ item.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="设备类型" v-if="ruleForm.stype == 10" :prop="ruleForm.stype == 10 ? 'category' : ''" style="width: 100%;">
          <el-select v-model="ruleForm.category" placeholder="请选择" style="width: 100%;" @change="getModelList">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" v-if="ruleForm.stype == 10" :prop="ruleForm.stype == 10 ? 'model' : ''" style="width: 100%;">
          <el-select v-model="ruleForm.model" placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in modelList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="制造商" v-if="ruleForm.stype == 10" :prop="ruleForm.stype == 10 ? 'manufacturer' : ''" style="width: 100%;">
          <el-select v-model="ruleForm.manufacturer" placeholder="请选择 " style="width: 100%;">
            <el-option v-for="item in manufacturerList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="安装位置" v-if="ruleForm.stype == 10" :prop="ruleForm.stype == 10 ? 'place' : ''">
          <el-cascader :options="placeList" :props="defaultProps" v-model="ruleForm.place" style="width:100%;" clearable ref="catalogueExport" @change="handleExportCatalogChange" />
        </el-form-item>
        <el-form-item label="网关编号" prop="gateway" style="width: 100%;">
          <el-input v-model="ruleForm.gateway"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      defaultProps: {
        children: "nodeList",
        label: "name",
        value: "id",
        checkStrictly: true
      },
      ruleForm: {
        id: null,
        gateway: null,
        manufacturer: null,
        category: null,
        model: null,
        place: null,
        project: null,
      },
      currentData: {},
      modelList: [],
      manufacturerList: [],
      categoryList: [],
      placeList: [],
      radiolist1: [
        {
          name: '设备',
          val: 10
        },
        {
          name: '边缘网关',
          val: 20
        },
        {
          name: '边缘服务器',
          val: 30
        }
      ],
      rules: {
        stype: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        category: [
          { required: true, message: "请选择设备类型", trigger: "change" },
        ],
        model: [
          { required: true, message: "请选择设备型号", trigger: "change" },
        ],
        manufacturer: [
          { required: true, message: "请选择制造商", trigger: "change" },
        ],
        gateway: [
          { required: true, message: "请输入网关编号", trigger: "blur" },
        ],
        place: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
  },
  props: ["orgVisible", "parentId", "id", "gateway", "project", "stype",  "category", "manufacturer", "model", "place"],
  watch: {
    orgVisible(newVal) {
      if (newVal) {
        this.ruleForm.id = this.id;
        this.ruleForm.gateway = this.gateway;
        this.ruleForm.stype = this.stype;
        this.ruleForm.category = this.category;
        this.ruleForm.manufacturer = this.manufacturer;
        this.ruleForm.model = this.model;
        this.ruleForm.project = this.project;
        this.ruleForm.place = this.place;
        this.ruleForm = { ...this.ruleForm }
        this.selectList();
      }
    }
  },
  methods: {
    handleExportCatalogChange(val) {
      this.$refs.catalogueExport.dropDownVisible = false
    },
    selectList() {
      this.request.categoryList().then((res) => {
        this.categoryList = res.data;
        if (this.id == null || this.id == undefined) {
          this.ruleForm.category = this.categoryList[0].id;
          this.getModelList(this.categoryList[0].id);
        } else {
          this.getModelList(this.ruleForm.category);
        }
      });
      this.request.manufacturerList().then((res) => {
        this.manufacturerList = res.data;
      });
      this.request.placeList({ project: this.project }).then((res) => {
        this.placeList = [res.data];
      });
    },
    getModelList(val) {
      const params = {};
      params.category = val;
      this.request.modelList(params).then((res) => {
        this.modelList = res.data;
      });
    },
    handleClose() {
      this.$refs["ruleForm"].resetFields();
      this.$emit("handleClose");
    },
    //确定
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id == null) {
            this.request.gatewayAdd(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$emit("listgateway");
                this.handleClose();
              }
            });
          } else {
            this.request.gatewayEdit(this.ruleForm).then((res) => {
              if (res.code == 0) {
                this.$emit("listgateway");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>