<template>
  <el-dialog :title="title" :visible.sync="editVisible" width="450px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm">
        <el-form-item label="升级固件">
          <el-select v-model="ruleForm.firmware" style="width:300px;" disabled>
            <el-option v-for="(item, index) in firmwareList" :key="index" :label="getFirmware(item)" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="升级版本" prop="matched">
          <el-input v-model="ruleForm.matched" style="width:300px;" placeholder="要升级的版本" disabled/>
        </el-form-item>
        <el-form-item label="匹配条件" prop="compare">
          <el-select v-model="ruleForm.compare" placeholder="请选择" style="width:300px;" disabled>
            <el-option label="等于" value="eq" />
            <el-option label="不等于" value="ne" />
          </el-select>
        </el-form-item>
        <el-form-item label="开始日期" prop="startDate">
          <el-input v-model="ruleForm.startDate" style="width:300px;" placeholder="（yyyy-MM-dd）这个日期后才可升级" clearable/>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-input v-model="ruleForm.startTime" style="width:300px;" placeholder="（HH:mm）每天这个时间后都可升级" clearable/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button @click="submit" type="primary">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "编辑升级任务",
      ruleForm: {
         id: null,
         stype: 10,
         taskType: 10,
         status: 10,
         firmware: null,
         matched: null,
         compare: null,
         startDate: null,
         startTime: null,
      },
      rules: {
        firmware: [
          {
            required: true,
            message: "请设置升级固件",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请设置升级状态",
            trigger: "blur",
          },
        ]
      },
      firmwareList: [],
    };
  },
  components: {
    
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["editVisible","taskItem"],
  watch: {
    editVisible(newValue) {
      if (newValue) {
        this.getFirmwareList();
        this.title="编辑升级任务";
        this.ruleForm={
          id: this.taskItem.id,
          stype: this.taskItem.stype,
          taskType: this.taskItem.taskType,
          status: this.taskItem.status,
          matched: this.taskItem.matched,
          compare: this.taskItem.compare,
          firmware: this.taskItem.firmware,
          startDate: this.taskItem.startDate,
          startTime: this.taskItem.startTime,
        };
      }
    },
  },
  methods: {
    onInput() {
      this.$forceUpdate();
    },
    getFirmwareList() {
      var params={
        pageNum: 1, 
        pageSize: 999,
      }
      this.request.firmwareDtuList(params).then((res) => {
        if (res.code == 0) {
          this.firmwareList=res.data;
        }
      });
    },
    getFirmware(item) {
      var name="模块";
      if (item.stype==20) {
        name="边缘网关";
      } else if (item.stype==30) {
        name="边缘服务器";
      }
      return item.name+"("+name+")";
    },
    handleClose() {
      this.ruleForm={
        id: null,
        stype: 10,
        taskType: 10,
        status: 10,
        matched: null,
        compare: null,
        firmware: null,
        startDate: null,
        startTime: null,
      };
      this.$emit("handleClose");
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.request.editDtuTask(this.ruleForm).then((res) => {
            if (res.code==0) {
              this.$message.success("修改成功");
              this.handleClose();
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>