<template>
   <div class="dilu-page">
    [能源管理] 开发中...
   </div>
 </template>
 
 <script>
 import DiluTable from "@/components/public/dilu-table.vue";
 import { mapState } from "vuex";
 export default {
   data() {
     return {
      searchParams: {
         project: null,      // 项目编码
         pageNum: 1,         // 页码
         pageSize: 24,       // 每页条数
       },
       tableConfig: {
         isTree: false,
         isPage: true,
       },
       tableData: [],
       total: 0,
       viewVisible: false,
       isAdd: true
     };
   },
   components: {
    DiluTable
   },
   computed: {
    ...mapState(["projectCurrentRowData"]),
   },
   mounted() {
    this.searchParams.project=this.projectCurrentRowData.projectCode;
    
   },
   methods: {
    
   },
 };
 </script>
 
 <style scoped>
 </style>