<template>
  <el-dialog :title="isAdd ? '新增协议' : '编辑协议'" :visible.sync="viewVisible" width="650px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="auto">
        <el-form-item label="设备类型" prop="category">
          <el-select v-model="ruleForm.category" style="width:400px;" @change="onChange" filterable clearable>
            <el-option v-for="(item, index) in catList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="model">
          <el-select v-model="ruleForm.model" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="设备厂商" prop="manufacturer">
          <el-select v-model="ruleForm.manufacturer" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in facList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="通讯波特" prop="baudrate">
          <el-input v-model="ruleForm.baudrate" @input="onInput"/>
        </el-form-item>
        <el-form-item label="地址类型" prop="addrType">
          <el-select v-model="ruleForm.addrType" placeholder="请选择" @input="onInput" style="width:400px;">
            <el-option label="十进制" :value=10 />
            <el-option label="十六进制" :value=16 />
          </el-select>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="ruleForm.name" @input="onInput"/>
        </el-form-item>
        <el-form-item label="详情页面" prop="detailPage">
          <el-select v-model="ruleForm.detailPage" style="width:400px;" filterable clearable>
            <el-option v-for="(item, index) in pageList" :key="index" :label="item.label" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="产品图片" prop="photo">
          <DiluUploadImg accept=".png,.jpg"  :limit="1" :fileList="photoList" @remove="removePhoto" @success="successPhoto"/>
        </el-form-item>
        <el-form-item label="原始协议" prop="original">
          <div class="form-item-div">
            <DiluUploadFile :limit="1" accept=".xls,.xlsx,.pdf,.doc,.docx,.txt,.jpg,.jpeg" :fileList="originalList" @remove="removeOriginal" @success="successOriginal" :uploaDisabled="false"/>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :style="{ color: buttonTextColor }" @click="handleClose">取 消</el-button>
      <el-button :style="{ color: buttonTextColor }" type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>


<script>

import { mapState } from "vuex";
import DiluUploadImg from "@/components/public/dilu-uploadImg.vue";
import DiluUploadFile from "@/components/public/dilu-uploadfile.vue";
export default {
  data() {
    return {
      ruleForm: {
        id: null,
        name: null,
        category: null,
        model: null,
        manufacturer: null,
        photo: "",
        detailPage: null,
        baudrate: 9600,
        dataBits: 8,
        parityCheck: 0,
        stopBits: 1,
        polling: 1000,
        timeout: 2000,
        accessLimits: 120,
        addrType: 10,
        original: null
      },
      photoList: [],
      originalList: [],
      catList: [],
      typeList: [],
      facList: [],
      proList: [],
      pageList: [
        {
          label:"device",
          value:"/subcontract/project/equipment/device"
        },
        {
          label:"equipmentSun",
          value:"/subcontract/project/equipment/equipmentSun"
        },
        {
          label:"equipmentMeter",
          value:"/subcontract/project/equipment/equipmentMeter"
        },
        {
          label:"equipmentNXRB",
          value:"/subcontract/project/equipment/equipmentNXRB"
        },
        {
          label:"equipmentWXYQ",
          value:"/subcontract/project/equipment/equipmentWXYQ"
        },
        {
          label:"equipmentAOXIN",
          value:"/subcontract/project/equipment/equipmentAOXIN"
        },
        {
          label:"equipmentYNNTSY",
          value:"/subcontract/project/equipment/equipmentYNNTSY"
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请指定设备名称",
            trigger: "blur",
          },
        ],
        category: [
          {
            required: true,
            message: "请指定设备类型",
            trigger: "blur",
          },
        ],
        model: [
          {
            required: true,
            message: "请指定设备型号",
            trigger: "blur",
          },
        ],
        manufacturer: [
          {
            required: true,
            message: "请指定设备厂商",
            trigger: "blur",
          },
        ],
        photo: [
          {
            required: true,
            message: "请上传产品图片",
            trigger: "blur",
          },
        ],
        detailPage: [
          {
            required: true,
            message: "请指定详情展示页面",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    viewVisible(newVal) {
      if (newVal) {
        if (this.isAdd) {
            //新增
            this.ruleForm.id = null;
            this.ruleForm.name = null;
            this.ruleForm.category = null;
            this.ruleForm.model = null;
            this.ruleForm.manufacturer = null;
            this.ruleForm.photo = "";
            this.ruleForm.detailPage = null;
            this.ruleForm.baudrate = 9600;
            this.ruleForm.dataBits = 8;
            this.ruleForm.parityCheck = 0;
            this.ruleForm.stopBits = 1;
            this.ruleForm.polling = 1000;
            this.ruleForm.timeout = 2000;
            this.ruleForm.accessLimits = 120;
            this.ruleForm.addrType = 10;
            this.ruleForm.original = null;
        } else {
          //修改
          this.ruleForm.id = this.currentData.id;
          this.ruleForm.name = this.currentData.name;
          this.ruleForm.category = this.currentData.category;
          this.ruleForm.model = this.currentData.model;
          this.ruleForm.manufacturer = this.currentData.manufacturer;
          this.ruleForm.detailPage = this.currentData.detailPage;
          this.ruleForm.photo = this.currentData.photo;
          this.ruleForm.baudrate = this.currentData.baudrate;
          this.ruleForm.dataBits = this.currentData.dataBits;
          this.ruleForm.parityCheck = this.currentData.parityCheck;
          this.ruleForm.stopBits = this.currentData.stopBits;
          this.ruleForm.polling = this.currentData.polling;
          this.ruleForm.timeout = this.currentData.timeout;
          this.ruleForm.accessLimits = this.currentData.accessLimits;
          this.ruleForm.addrType = this.currentData.addrType;
          this.ruleForm.original = this.currentData.original;
          let photo = [];
          if (this.ruleForm.photo!=null) {
            photo.push({
              url: this.ruleForm.photo,
              name: this.ruleForm.photo.slice(this.ruleForm.photo.lastIndexOf("/")+1,this.ruleForm.photo.length)
            });
          }
          this.photoList = photo;
          let originalTemp = [];
          if (this.ruleForm.original!=null) {
            originalTemp.push({
              url: this.ruleForm.original,
              name: this.ruleForm.original.slice(this.ruleForm.original.lastIndexOf("/")+1,this.ruleForm.original.length)
            });
          }
          this.originalList = originalTemp;
        }
        this.categoryList();
        this.manufacturerList();
        if (this.ruleForm.category!=null) {
          this.modelList();
        }
      }
    },
  },
  components: {
    DiluUploadImg,
    DiluUploadFile
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["viewVisible", "currentData", "isAdd"],
  methods: {
    onInput() {
      //input输入框无法编辑
      this.$forceUpdate();
    },
    onChange() {
      this.ruleForm.model=null;
      this.modelList();
    },
    protocolList() {
      var params={
        pageNum:1,
        pageSize:500
      };
      this.request.protocolList(params).then((res) => {
          if (res.code==0) {
            this.proList=[];
            for (var i=0; i<res.data.length; i++) {
              var item={
                id: res.data[i].id,
                name: res.data[i].name+"|"+res.data[i].codec
              };
              this.proList.push(item);
            }
          }
      });
    },
    categoryList() {
       var params={
        pageNum: 1,        
        pageSize: 200, 
       }
       this.request.categoryList(params).then((res) => {
          if (res.code==0) {
            this.catList=res.data;
          }
       });
    },
    modelList() {
      var params = {
        category: this.ruleForm.category,
        pageNum: 1,        
        pageSize: 200, 
      }
      this.request.modelList(params).then((res) => {
          if (res.code==0) {
            this.typeList=res.data;
          }
      });
    },
    manufacturerList() {
      var params={
        pageNum: 1,        
        pageSize: 200, 
      }
      this.request.manufacturerList(params).then((res) => {
          if (res.code==0) {
            this.facList=res.data;
          }
      });
    },
    removePhoto(e) {
      const i = this.photoList.findIndex((x) => x.name == e);
      this.photoList.splice(i, 1);
      this.ruleForm.photo = null;
    },
    successPhoto(e) {
      this.ruleForm.photo = e.url;
      this.photoList = e.fileList;
    },
    removeOriginal(e) {
      const i = this.originalList.findIndex((x) => x.name == e);
      this.originalList.splice(i, 1);
      this.ruleForm.original = null;
    },
    successOriginal(e) {
      this.originalList = e.fileList;
      this.ruleForm.original = e.url;
    },
    //处理关闭
    handleClose() {
      this.ruleForm = {
        id: null,
        name: null,
        category: null,
        model: null,
        manufacturer: null,
        photo: "",
        detailPage: null,
        baudrate: 9600,
        dataBits: 8,
        parityCheck: 0,
        stopBits: 1,
        polling: 1000,
        timeout: 2000,
        accessLimits: 120,
        addrType: 10,
        original: null
      };
      this.$refs["ruleForm"].resetFields();
      this.$emit("viewClose");
    },
    //提交保存
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.request.originalAdd(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("新增成功");
                this.$emit("originalList");
                this.handleClose();
              }
            });
          } else {
            this.request.originalEdit(this.ruleForm).then((res) => {
              if (res.code==0) {
                this.$message.success("编辑成功");
                this.$emit("originalList");
                this.handleClose();
              }
            });
          }
        }
      });
    },
  },
};
</script>


<style scoped>
.from-body {
  padding: 0 60px;
  box-sizing: border-box;
}
</style>