<template>
  <!-- 账号管理 -->
  <div class="dilu-page">
    <!-- 搜索区域 -->
    <div class="search">
      <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="账号">
          <el-input v-model="searchParams.account" placeholder="请输入账号"/>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="searchParams.name" placeholder="请输入姓名"/>
        </el-form-item>
      </el-form>
      <div class="search-submit">
        <el-button icon="el-icon-search" type="primary" @click="search">搜索</el-button>
        <el-button icon="el-icon-refresh" type="primary" @click="reset">重置</el-button>
      </div>
    </div>

    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button icon="el-icon-plus" type="primary" @click="add">新增</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="id" label="ID" align="center"/>
      <el-table-column prop="name" label="员工姓名" align="left"/>
      <el-table-column prop="account" label="登录账号" align="left"/>
      <el-table-column prop="orgName" label="所属部门" align="left"/>
      <el-table-column prop="roleName" label="用户角色" align="center"/>
      <el-table-column prop="status" label="在职状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status==10">在职</span>
          <span v-if="scope.row.status==20">离职</span>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="创建时间" align="center" width="180px"/>
      <el-table-column prop="address" label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ type: 'see', data: scope.row }">查看</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'edit', data: scope.row }">编辑</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'share', data: scope.row }">一键分享</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'resetPassword', data: scope.row }">重置密码</el-dropdown-item>
                <el-dropdown-item :command="{ type: 'remove', data: scope.row }">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <!-- 新增账号 -->
    <AccountManagementAdd :accountVisible="accountVisible" @accountClose="accountClose" @accountManagementList="accountManagementList" :accountManagementAddState="accountManagementAddState" :currentAccount="currentAccount"/>

     <!-- 分享账号 -->
    <AccountShare :shareInfo="shareInfo" :shareVisible="shareVisible" @shareClose="shareClose"/>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AccountManagementAdd from "./module/accountManagementAdd.vue";
import AccountShare from "./module/accountShare.vue";
import { mapState } from "vuex";
import { randomString } from "@/utils/util/validate";
export default {
  data() {
    return {
      currentAccount: {}, //当前账号
      accountManagementAddState: 0, //弹窗类型 0：新增 1：编辑 2：查看
      isSerch: false,
      accountVisible: false, //新增账号
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      tableData: [],
      shareInfo: {},
      shareVisible: false,
      total: 0,
      searchParams: {
        pageNum: 1,   //页码
        pageSize: 12, //每页条数
        name: "",     //姓名
        account: "",  //账号
      },
    };
  },
  components: {
    DiluTable,
    AccountManagementAdd,
    AccountShare,
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.accountManagementList();
  },
  methods: {
    // 翻页
    currentChange(e) {
      if (!this.isSerch) {
        this.searchParams.pageNum = 1;
        this.searchParams.pageSize = 10;
      }
      this.searchParams.pageNum = e;
      this.accountManagementList();
    },
    //新增账号
    add() {
      this.accountManagementAddState = 0;
      this.accountVisible = true;
    },
    //关闭新增账号弹窗
    accountClose() {
      this.accountVisible = false;
    },
    //搜索
    search() {
      this.isSerch = true; //是否在查询
      this.searchParams.pageNum = 1;
      this.total = 0;
      this.accountManagementList();
    },
    //重置
    reset() {
      this.isSerch = false; //是否在查询
      this.total = 0;
      this.searchParams.pageNum = 1;
      this.searchParams.pageSize = 10;
      this.searchParams.name = "";
      this.searchParams.account = "";
      this.accountManagementList();
    },
    //操作
    handleCommand(e) {
      if (e.type == "see") {
        this.see(e.data);
      }
      if (e.type == "edit") {
        this.edit(e.data);
      }
      if (e.type == "share") {
        this.share(e.data);
      }
      if (e.type == "resetPassword") {
        this.resetPassword(e.data);
      }
      if (e.type == "remove") {
        this.remove(e.data);
      }
    },
    //查看
    see(e) {
      this.request.accountManagementInfo({id:e.id}).then((res) => {
        if (res.code==0) {
          this.currentAccount = res.data;
          this.accountManagementAddState = 2;
          this.accountVisible = true;
        }
      });
    },
    //编辑
    edit(e) {
      this.request.accountManagementInfo({id:e.id}).then((res) => {
        if (res.code==0) {
          this.currentAccount = res.data;
          this.accountManagementAddState = 1;
          this.accountVisible = true;
        }
      });
    },
    //删除
    remove(e) {
      this.$confirm("确认删除该账号？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.request.accountManagementDelete({id:e.id}).then((res) => {
          if (res.code==0) {
            this.$message.success("删除成功");
            this.accountManagementList();
          }
        });
      }).catch(() => {});
    },
    //打开分享弹窗
    share(e) {
      this.request.accountManagementShare(e.id).then((res) => {
        if (res.code==0) {
          this.shareInfo = {
            password: res.data.post,
            account: res.data.account,
            domain: res.data.domain,
          };
          this.shareVisible = true;
        }
      });
    },
    //关闭分享弹窗
    shareClose() {
      this.shareVisible = false;
    },
    //重置密码
    resetPassword(e) {
      this.$confirm("确认随机重置密码吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let newPassword = randomString(6);
        this.request.accountManagementResetPassword({ id: e.id, password: newPassword }).then((res) => {
          if (res.code==0) {
            this.$message({
              type: "success",
              message: "重置成功!",
            });
            this.$alert(`新密码:${newPassword}`, {
              confirmButtonText: "确定",
              callback: () => {
                this.accountManagementList();
              },
            });
          } 
        });
      });
    },
    //账号管理-列表（分页）
    accountManagementList() {
      this.request.listChildAccounts(this.searchParams).then((res) => {
        this.tableData = res.data;
        this.total = res.dataMaxCount;
      });
    },
  },
};
</script>

<style scoped>
</style>