import { render, staticRenderFns } from "./dilu-uploadImg.vue?vue&type=template&id=80592efc&scoped=true"
import script from "./dilu-uploadImg.vue?vue&type=script&lang=js"
export * from "./dilu-uploadImg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80592efc",
  null
  
)

export default component.exports