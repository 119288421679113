<template>
  <div class="dilu-page">
    
    <!-- 按钮区域 -->
    <div class="operation-div">
      <el-button :style="{ color: buttonTextColor }" icon="el-icon-plus" type="primary" @click="add">新增制造商</el-button>
    </div>

    <!-- 表格 -->
    <DiluTable :list="tableData" :tableConfig="tableConfig" :total="total" :pageSize="searchParams.pageSize" @currentChange="currentChange">
      <el-table-column prop="id" label="制造商ID" align="left"/>
      <el-table-column prop="name" label="制造商名称" align="left"/>
      <el-table-column prop="createdAt" label="创建时间" align="center" :formatter="formatDate"/>
      <el-table-column label="操作" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="table-operation">
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </DiluTable>

    <AddManufacturer :viewVisible="viewVisible" @viewClose="viewClose" :currentData="currentData" @manufacturerList="manufacturerList" :isAdd="isAdd"/>

  </div>
</template>


<script>
import DiluTable from "@/components/public/dilu-table.vue";
import AddManufacturer from "./module/addManufacturer";
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchParams: {
         pageNum: 1,         // 页码
         pageSize: 20,       // 每页条数
      },
      tableConfig: {
        isTree: false,
        isPage: true,
      },
      tableData: [],
      total: 0,
      viewVisible: false,
      currentData: {},
      isAdd: true
    };
  },
  components: {
    DiluTable,
    AddManufacturer
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  mounted() {
    this.manufacturerList();
  },
  methods: {
    formatDate(row) {
       if (row.createdAt!=undefined&&row.createdAt.length>=10) {
         return row.createdAt.substring(0,10);
       } else {
         return "";
       }
    },
    //翻页
    currentChange(e) {
       this.searchParams.pageNum = e;
       this.manufacturerList();
    },
    //新增
    add() {
     this.isAdd = true;
     this.viewVisible = true;
     this.currentData = {};
    },
    //编辑
    edit(e) {
     this.isAdd = false;
     this.currentData = e;
     this.viewVisible = true;
    },
    //关闭新增编辑菜单弹窗
    viewClose() {
      this.viewVisible = false;
    },
    //查询列表
    manufacturerList() {
      this.request.manufacturerList().then((res) => {
         if (res.code==0) {
           this.tableData = res.data;
           this.total = res.dataMaxCount;
         }
      });
    },
  },
};
</script>

<style scoped>
</style>