<template>
  <el-dialog :title="title" :visible.sync="editVisible" width="1024px" :before-close="handleClose" :close-on-click-modal="false" center>
    <div class="from-body" style="width:100%;display:flex;flex-direction:column;">
      <div class="search">
       <el-form ref="form" :model="searchParams" label-width="auto" class="search-form">
        <el-form-item label="网关类型">
            <el-select v-model="searchParams.stype" style="width:200px;margin-right:15px;" disabled>
              <el-option label="模块" value=10 />
              <el-option label="边缘网关" value=20 />
              <el-option label="边缘服务器" value=30 />
            </el-select>
        </el-form-item>
        <el-form-item label="匹配版本" prop="matched">
          <el-input v-model="searchParams.matched" style="width:200px;margin-right:15px;" disabled/>
        </el-form-item>
        <el-form-item label="匹配条件" prop="compare">
          <el-select v-model="searchParams.compare" style="width:200px;margin-right:15px;" disabled>
            <el-option label="等于" value="eq" />
            <el-option label="不等于" value="ne" />
          </el-select>
        </el-form-item>
       </el-form>
      </div>
      <DiluTable :list="tableData" :tableConfig="tableConfig" :pageSize="searchParams.pageSize" @currentChange="currentChange" :total="total">
       <el-table-column prop="gateway" label="网关编号" width="150" align="center"/>
       <el-table-column prop="status" label="在线状态" width="120" align="center">
          <template slot-scope="scope">
              <span v-if="scope.row.status == 'yes'">在线</span>
              <span v-if="scope.row.status == 'no'">离线</span>
          </template>
       </el-table-column>
       <el-table-column prop="firmware" label="固件版本" width="180" align="left"/>
       <el-table-column prop="projectCode" label="关联项目" width="200" align="left"/>
       <el-table-column prop="projectName" label="项目名称" width="200" align="left"/>
       <el-table-column prop="remark" label="操作" align="center" fixed="right" min-width="120">
          <template slot-scope="scope">
            <div class="table-operation">
              <el-button @click="joinGray(scope.row)" type="text">加入测试</el-button>
            </div>
          </template>
       </el-table-column>
      </DiluTable>
    </div>  
  </el-dialog>
</template>

<script>
import DiluTable from "@/components/public/dilu-table.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      title: "选择灰度测试设备",
      tableConfig: {
         isTree: false,
         isPage: true, 
      },
      searchParams: {
         stype: null,
         target: null,
         taskId: null,
         matched: null,
         compare: null,
         pageNum: 1, 
         pageSize: 12,
      },
      total: 0,
      tableData: [],
    };
  },
  components: {
    DiluTable,
  },
  computed: {
    ...mapState(["buttonTextColor"]),
  },
  props: ["editVisible","grayTask"],
  watch: {
    editVisible(newValue) {
      if (newValue) {
        this.searchParams.stype=this.grayTask.firmwareType;
        this.searchParams.target=this.grayTask.firmwareVersion;
        this.searchParams.taskId=this.grayTask.id;
        this.searchParams.matched=this.grayTask.matched;
        this.searchParams.compare=this.grayTask.compare;
        this.getGatewayList();
      }
    },
  },
  methods: {
    getGatewayList() {
      this.request.searchDtuList(this.searchParams).then((res) => {
        if (res.code == 0) {
          this.tableData=res.data;
          this.total=res.dataMaxCount;
        }
      });
    },
    joinGray(e){
      var params={
        taskId: this.searchParams.taskId,
        deviceId: e.id
      };
      this.request.addOta(params).then((res) => {
        if (res.code == 0) {
          this.handleClose();
        }
      });
    },
    handleClose() {
      this.$emit("handleClose");
    },
    currentChange(e) {
       this.searchParams.pageNum = e;
       this.getGatewayList();
    },
  },
};
</script>
<style scoped>
</style>